/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'verified': {
    width: 17,
    height: 17,
    viewBox: '0 0 17 17',
    data: '<path pid="0" d="M7.236 16.035c.332.453.71.965 1.254.965s.922-.513 1.254-.965l.003-.003c.193-.262.432-.586.657-.636.2 0 .505.133.8.262.537.234 1.132.436 1.531.205.463-.268.53-.867.595-1.446.036-.33.083-.74.206-.864.123-.123.531-.17.86-.206h.002c.58-.066 1.178-.134 1.445-.595.264-.457.026-1.004-.204-1.534-.134-.308-.3-.692-.254-.867.043-.16.369-.4.63-.594.453-.333.965-.712.965-1.257 0-.545-.512-.924-.964-1.257-.262-.193-.588-.434-.63-.594-.047-.174.12-.559.254-.867.23-.53.467-1.077.203-1.533-.266-.463-.865-.53-1.443-.595h-.001c-.33-.038-.74-.083-.862-.207-.123-.122-.169-.532-.206-.862v-.002c-.065-.579-.132-1.177-.594-1.445-.402-.233-.997-.03-1.532.204-.296.129-.605.252-.867.255-.158-.042-.396-.365-.589-.626L9.745.965C9.412.512 9.035 0 8.49 0c-.544 0-.922.513-1.255.964l-.003.004c-.193.262-.431.586-.656.636-.198 0-.503-.132-.798-.26l-.002-.002c-.537-.233-1.133-.435-1.532-.204-.463.268-.53.866-.595 1.445-.036.33-.082.74-.205.864-.123.123-.532.17-.86.206H2.58c-.579.066-1.178.134-1.444.596-.263.455-.026 1.002.203 1.532v.001c.135.308.302.693.255.867-.042.16-.369.4-.63.594C.512 7.576 0 7.955 0 8.5c0 .545.512.924.964 1.257.262.193.588.434.63.594.047.174-.119.559-.253.867-.23.53-.468 1.077-.204 1.534.266.462.865.53 1.443.594h.002c.33.038.739.083.861.207.123.122.17.532.206.862v.002c.065.579.132 1.177.595 1.445.4.233.995.03 1.532-.204.296-.129.598-.259.866-.255.159.042.396.365.59.626l.003.006z" _fill="#6D62FC"/><path pid="1" d="M12.802 6.172a.565.565 0 010 .834l-5.126 4.821a.647.647 0 01-.443.173.647.647 0 01-.443-.173L4.18 9.37a.565.565 0 010-.833c.244-.23.64-.23.886 0l2.168 2.039 4.683-4.404a.655.655 0 01.886 0z" _fill="#fff"/>'
  }
})
